@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font Family */
@import url(assets/fonts/font.css);
/* ________________________________________________________________________________________ */

body {
  margin: 0;
  font-family: "Century Gothic", sans-serif !important;
  @apply text-primary;
}

/* Scrollbar css */
.user-donated::-webkit-scrollbar {
  width: 0px;
}
/* Input Number Arrow Hidden */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Placeholder Custom Css */

/* ________________________________________________________________________________________ */

/* Custom Container */
.containers {
  @apply px-4 lgs:px-0 lg:max-w-[1195px] mx-auto;
}
/* ________________________________________________________________________________________ */

.signupcontainers {
  @apply px-4 lg:px-0 lg:max-w-[1440px] mx-auto;
}

/* HTML Tag Custom Css */

/* Heading Tag */
h1 {
  @apply font-bold text-xl tab:text-4xl lg:text-46 leading-[56.4px];
}
h2 {
  @apply font-bold text-26 md:text-4xl;
}
h3 {
  @apply font-bold text-3xl;
}
h4 {
  @apply font-bold text-2xl text-primary;
}
h5 {
  @apply font-bold text-xl;
}
h6 {
  @apply font-bold text-base sm:text-lg;
}

/* Paragraph Tag */
p {
  @apply text-xs sm:text-sm;
}

/* Span Tag */
span {
  @apply text-xs;
}

/* Label Tag */
label {
  @apply text-sm leading-none mb-2.5 inline-block;
}

/* Strong Tag */
strong {
  @apply text-10 leading-[12.26px];
}
/* ________________________________________________________________________________________ */

/* Button Variable */

/* Button Color */
.btn-primary {
  @apply bg-primary border-2 border-primary text-white hover:bg-transparent hover:text-primary hover:border-primary duration-200;
}
.btn-outline-primary {
  @apply bg-transparent border-2 border-primary text-primary hover:bg-primary hover:text-white hover:border-primary duration-200;
}
.btn-outline-white {
  @apply bg-transparent border-2 border-white text-white hover:bg-white hover:text-primary hover:border-white duration-200;
}
.btn-success {
  @apply bg-success border-2 border-success text-white hover:bg-transparent hover:text-white hover:border-white duration-200;
}
/* Button Size */
.btn-big {
  @apply rounded-full text-sm py-2 tab:py-[14px] px-30 tab:px-36 font-bold leading-[17.17px];
}
.btn-header {
  @apply rounded-full text-sm py-4 px-36 font-bold leading-[17.17px];
}
.btn-default {
  @apply rounded-full text-sm py-2 px-26 font-bold leading-[17.17px];
}
.btn-default20 {
  @apply rounded-full text-sm py-2 px-5 font-bold leading-[17.17px] border-[1.5px];
}
.btn-small {
  @apply rounded-full text-xs py-1.5 px-4 font-bold leading-[14.71px];
}
/* ________________________________________________________________________________________ */

/* Input Custom Css */
input {
  @apply border border-primary/20 rounded-md focus:outline-none focus:border-primary h-10 text-sm w-full placeholder:text-sm;
}

.card-input {
  @apply border border-primary/20 rounded-md focus:outline-none focus:border-primary h-10 text-sm w-full placeholder:text-sm;
}
.text-input,
textarea {
  @apply px-3 py-3;
}
.input-slash::placeholder {
  @apply absolute -top-1 -left-4 text-17 z-0 pl-6 pe-3 py-3;
}

.amount-input {
  @apply placeholder:text-primary px-5;
}
textarea {
  @apply border border-primary/20 rounded-md focus:outline-none focus:border-primary h-10 text-sm w-full placeholder:text-sm;
}
textarea::placeholder {
  @apply absolute -top-1.5 -left-4 text-lg z-0 pl-6 pe-3 py-3;
}

/* ________________________________________________________________________________________ */

.input {
  @apply border border-primary/15 rounded-lg px-4 py-2.5;
}

.inputform {
  @apply border border-primary/15 rounded-lg p-3 w-full text-sm;
}

.inputformpayment {
  @apply border border-primary/15 rounded-lg px-3 py-3 w-full;
}

/* Dashboard */
.dashboard-part {
  @apply bg-cgreen px-4 pb-4 lg:px-30 lg:pb-30;
}
.dashboard-card {
  @apply bg-white shadow-card2 rounded-10;
}
.header-modal {
  @apply absolute top-[100px] sm:top-[115px] sm:right-[73px] rounded-lg shadow-md bg-white z-50 h-fit min-w-[350px] sm:min-w-fit;
}
@media (max-width: 575px) {
  .header-modal {
    @apply left-1/2 -translate-x-1/2;
  }
}

/* Home Page Extra Css */
.hero-section {
  @apply relative md:h-[700px] bg-no-repeat bg-cover bg-[40%_65%] flex items-center py-[70px];
  background-image: url("./assets/svg/background/hero.png");
}
.hero-overlay {
  @apply bg-white md:bg-hero absolute h-full w-full opacity-50 md:opacity-70;
}
.help-card {
  @apply relative p-11 rounded-[20px] overflow-hidden;
}
.need-help::before {
  background-image: url("./assets/svg/background/help-card.svg");
}
.help-card::before {
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}
.help-families::before {
  background-image: url("./assets/svg/background/help-card2.png") !important;
}
.help-give::before {
  background-image: url("./assets/svg/background/help-card3.png") !important;
}
.work-overlay {
  @apply absolute left-0 -top-[130px] md:-top-[118px] h-[330px] md:h-[500px] w-full bg-white;
}
.faq-overlay {
  @apply absolute left-0 -top-[160px] md:-top-[118px] h-[330px] md:h-[500px] w-full bg-white;
}

.user-card {
  @apply grid gap-4 md:gap-30;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
}
@media (max-width: 569px) {
  .user-card {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}

.vendor-dashboard-card {
  @apply grid gap-4;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.profile-bank-card {
  @apply grid gap-4;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.help-section {
  background-image: url("assets/svg/background/get-help.svg");
}
@media (max-width: 767px) {
  .help-section {
    background-image: url("assets/svg/background/get-help-mobile.svg");
  }
}
.vendor-section {
  background-image: url("assets/svg/background/vendor-banner.svg");
}
@media (max-width: 767px) {
  .vendor-section {
    background-image: url("assets/svg/background/vendor-banner-mobile.svg");
  }
}

.signupcard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

strong {
  @apply text-primary font-bold;
}

/* Border CSS */
/* Border Bottom Custom */
.b-b {
  @apply border-b border-primary/20;
}
/* ________________________________________________________________________________________ */

.content-center {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.custom-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-radio {
  position: relative;
}

.custom-radio::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #7abd20;
  transition: transform 0.2s ease-in-out;
}

.custom-radio:checked::before {
  transform: translate(-50%, -50%) scale(1);
}

.valierror {
  @apply text-red-600 mt-1 text-sm;
}

div:where(.swal2-icon).swal2-error.swal2-icon-show .swal2-x-mark {
  margin: 4px;
}

.swal2-popup.swal2-toast
  .swal2-success.swal2-icon-show
  .swal2-success-line-long {
  margin: 4px;
}
.swal2-popup.swal2-toast
  .swal2-success.swal2-icon-show
  .swal2-success-line-tip {
  margin: 3px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.avatar span {
  @apply tab:text-[28px] text-lg;
}

.react-datepicker-wrapper {
  @apply w-full;
}

.vendor-status-card::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.vendor-status-card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.vendor-status-card::-webkit-scrollbar-thumb {
  border-radius: 10px;
  @apply bg-primary;
}

/* Handle on hover */
.vendor-status-card::-webkit-scrollbar-thumb:hover {
  @apply bg-primary;
}

/* Stepper */
.step-item {
  @apply relative flex flex-col justify-center first:items-start last:items-end items-center w-full first:w-fit;
}
.step-item:nth-child(2) {
  @apply !items-end;
}
.step-item:not(:first-child):before {
  @apply content-[''] bg-slate-200 absolute w-full h-[3px] right-4 top-1/2 -translate-y-1/2;
}
.step {
  @apply h-7 w-7 tab:w-10 tab:h-10 text-sm tab:text-base flex items-center justify-center z-10 relative bg-[#F4F4F5] rounded-full font-semibold text-primary;
}
.active .step {
  @apply bg-primary text-white;
}
.complete .step {
  @apply bg-primary text-white;
}
.complete p {
  @apply text-white;
}
.complete:not(:first-child):before,
.active:not(:first-child):before {
  @apply bg-primary;
}
