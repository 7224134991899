table {
  @apply w-full border-separate border-spacing-y-2;
}
table thead tr th {
  @apply pt-5 pb-2.5 first:ps-4 px-2.5 text-xs text-left border-b border-primary/20;
}
table thead tr th strong {
  @apply text-xs;
}
table thead tr {
  @apply border-b border-primary;
}
table tbody tr td {
  @apply py-5 first:ps-4 px-2.5 text-sm text-left;
}
table {
  border-collapse: separate;
  border-spacing: 0 16px;
}
tbody tr td:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

tbody tr td:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
