/* Payment Receipt */
.payment-slip {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 700px;
  height: 900px;
  padding: 40px;
  border-radius: 10px;
  /* background: linear-gradient(120deg, #667eea, #764ba2); */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: #000000;
  position: relative;
  overflow: hidden;
}
.payment-slip:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  /* background: rgba(255, 255, 255, 0.1); */
  transform: skewX(-45deg);
}
.transaction-id {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}
.header {
  text-align: center;
  margin-bottom: 30px;
}
.header h2 {
  font-size: 28px;
  margin: 0;
}
.details p {
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.6;
}
.details strong {
  font-weight: bold;
}
.total-amount p {
  margin: 5px 0;
  font-size: 24px;
  font-weight: bold;
  text-align: right;
}
